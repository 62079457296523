<template>
  <section class="section" id="gerenciar-ingressos">
    <div class="container-admin">
      <div class="columns is-multiline">
        <div class="column">
          <h3 class="title is-3 has-text-primary">
            Gerenciamento de Facilidades
          </h3>
        </div>
        <div class="column is-1" style="min-width: fit-content">
          <b-button type="is-secondary" icon-left="tag" expanded @click="isModalGerenciarCategoriaActive = true">
            Gerenciar Categorias
          </b-button>
        </div>
        <div class="column is-1" style="min-width: fit-content">
          <router-link to="/admin/cadastrar-facilidade" class="button is-primary is-fullwidth">
            <b-icon icon="plus" class="mr-1" />
            Cadastrar Facilidade
          </router-link>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column">
          <b-field label="Categoria">
            <b-select v-model="filtro.facilidadeCategoriaId" expanded>
              <option v-for="categoria in facilidadeCategorias" :key="categoria.id" :value="categoria.id">
                {{ categoria.nome }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column" style="display: flex;align-items: flex-end;">
          <b-button type="is-secondary" expanded @click="getFacilidades">
            Buscar
          </b-button>
        </div>
      </div>
      <section
        v-if="facilidadeCategorias.length === 0 && !isLoading"
        class="section has-background-light has-text-centered"
        style="border-radius: 10px"
      >
        Nenhum resultado encontrado para a sua busca.
      </section>
      <b-table v-else :data="facilidades" :loading="isLoading" hoverable mobile-cards>
        <b-table-column field="id" label="ID" centered v-slot="props" :width="200">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="nome" label="Nome" v-slot="props">
          {{ props.row.nome }}
        </b-table-column>

        <b-table-column field="acoes" label="Ações" v-slot="props" centered>
          <b-button type="is-secondary" size="is-small" class="mr-3" icon-left="pencil" @click="editFacilidade(props.row)" />
          <b-button type="is-danger" size="is-small" icon-left="delete" @click="deleteFacilidade(props.row)" />
        </b-table-column>
      </b-table>
    </div>
    <ModalGerenciarFacilidadeCategoria :active="isModalGerenciarCategoriaActive" @close="closeModalGerenciarCategoria" />
    <ModalConfirmar :ativo="isModalConfirmDeleteActive" @cancel="cancelDeleteFacilidade" @confirm="confirmDeleteFacilidade" />
  </section>
</template>

<script>
import ModalConfirmar from '@/components/ModalConfirmar.vue';
import ModalGerenciarFacilidadeCategoria from '@/components/Cadastros/Facilidade/ModalGerenciarFacilidadeCategoria.vue';
import facilidadeCategoriaService from '@/services/facilidadeCategoriaService';
import facilidadeService from '@/services/facilidadeService';

export default {
  name: 'GerenciarFacilidades',
  components: { ModalGerenciarFacilidadeCategoria, ModalConfirmar },
  data() {
    return {
      isLoading: false,
      isModalGerenciarCategoriaActive: false,
      isModalConfirmDeleteActive: false,
      filtro: {},
      facilidades: [],
      facilidadeCategorias: [],
      trashFacilidade: null,
    };
  },
  created() {
    this.isLoading = true;
    this.getCategorias().then(() => {
      this.getFacilidades();
    });
  },
  methods: {
    async getCategorias() {
      return facilidadeCategoriaService.get().then(({ data }) => {
        this.facilidadeCategorias = data;
        const [categoria] = data;
        if (categoria) {
          this.filtro.facilidadeCategoriaId = categoria.id;
        }
      });
    },
    async getFacilidades() {
      this.isLoading = true;
      const { facilidadeCategoriaId } = this.filtro;
      if (facilidadeCategoriaId) {
        await facilidadeService.getByCategoria(facilidadeCategoriaId).then(({ data }) => {
          this.facilidades = data;
        });
      }
      this.isLoading = false;
    },
    closeModalGerenciarCategoria() {
      this.isModalGerenciarCategoriaActive = false;
    },
    editFacilidade({ id }) {
      this.$router.push(`/admin/editar-facilidade/${id}`);
    },
    deleteFacilidade(facilidade) {
      this.trashFacilidade = facilidade;
      this.isModalConfirmDeleteActive = true;
    },
    cancelDeleteFacilidade() {
      this.trashFacilidade = null;
      this.isModalConfirmDeleteActive = false;
    },
    confirmDeleteFacilidade() {
      facilidadeService
        .delete(this.trashFacilidade.id)
        .then(() => {
          this.$buefy.snackbar.open(`Facilidade excluída com sucesso!`);
          this.getFacilidades();
          this.trashFacilidade = null;
          this.isModalConfirmDeleteActive = false;
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            duration: 5000,
            message: 'Erro ao excluir tarifa',
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        });
    },
  },
};
</script>
