<template>
  <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-label="Example Modal" aria-modal>
    <template #default>
      <div class="modal-card">
        <header class="modal-card-head has-background-white">
          <p class="modal-card-title has-text-primary has-text-weight-semibold">
            {{ title }}
          </p>
          <b-button v-if="step === 'list'" type="is-primary" icon-left="plus" @click="step = 'create'" class="mr-5">
            Cadastrar
          </b-button>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
          <b-loading :active.sync="isLoading" :is-full-page="false" />
          <div v-if="step === 'list'">
            <b-table :data="categorias" :loading="isLoading" hoverable mobile-cards>
              <b-table-column field="id" label="ID" centered v-slot="props" :width="100">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column field="nome" label="Nome" v-slot="props">
                {{ props.row.nome }}
              </b-table-column>

              <b-table-column field="acoes" label="Ações" v-slot="props" centered>
                <b-button type="is-secondary" icon-left="pencil" size="is-small" class="mr-2" @click="editCategoria(props.row)" />
                <b-button type="is-danger" icon-left="delete" size="is-small" @click="deleteCategoria(props.row)" />
              </b-table-column>
            </b-table>
          </div>
          <div v-if="step === 'create'">
            <form @submit="handleSubmitCreate">
              <div class="columns is-multiline">
                <b-field label="Nome" class="column is-full">
                  <b-input type="text" name="nome" v-model="form.nome" placeholder="Digite" required />
                </b-field>
                <div class="column is-half">
                  <b-button type="is-light" expanded @click="step = 'list'">
                    Cancelar
                  </b-button>
                </div>
                <div class="column is-half">
                  <b-button type="is-primary" expanded tag="button" native-type="submit" label="Salvar" />
                </div>
              </div>
            </form>
          </div>
          <div v-if="step === 'edit'">
            <form @submit="handleSubmitEdit">
              <div class="columns is-multiline">
                <b-field label="Nome" class="column is-full">
                  <b-input type="text" name="nome" v-model="form.nome" placeholder="Digite" required />
                </b-field>
                <div class="column is-half">
                  <b-button type="is-light" expanded @click="step = 'list'">
                    Cancelar
                  </b-button>
                </div>
                <div class="column is-half">
                  <b-button type="is-primary" expanded tag="button" native-type="submit" label="Salvar" />
                </div>
              </div>
            </form>
          </div>
        </section>
        <ModalConfirmar :ativo="isModalConfirmDeleteActive" @cancel="cancelDeleteCategoria" @confirm="confirmDeleteCategoria" />
      </div>
    </template>
  </b-modal>
</template>

<script>
import facilidadeCategoriaService from '@/services/facilidadeCategoriaService';
import ModalConfirmar from '@/components/ModalConfirmar.vue';

export default {
  components: { ModalConfirmar },
  name: 'ModalGerenciarFacilidadeCategoria',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isModalActive: this.active,
      isModalConfirmDeleteActive: false,
      step: 'list',
      form: {},
      categorias: [],
      trashCategoria: null,
    };
  },
  watch: {
    active(newValue) {
      this.isModalActive = newValue;
    },
    isModalActive(newValue) {
      if (!newValue) {
        this.closeModal();
      }
    },
    step(newValue) {
      if (newValue === 'list') {
        this.form = {};
        this.getCategorias();
      }
    },
  },
  computed: {
    title() {
      switch (this.step) {
        case 'edit':
          return 'Editar Categoria';
        case 'create':
          return 'Cadastrar Categoria';
        default:
          return 'Gerenciar Categorias';
      }
    },
  },
  created() {
    this.getCategorias();
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    handleSubmitCreate(event) {
      event.preventDefault();
      this.isLoading = true;

      facilidadeCategoriaService
        .post(this.form)
        .then(() => {
          this.form = {};
          this.step = 'list';
          this.$buefy.snackbar.open(`Categoria cadastrada com sucesso!`);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleSubmitEdit(event) {
      event.preventDefault();
      this.isLoading = true;

      facilidadeCategoriaService
        .put(this.form)
        .then(() => {
          this.form = {};
          this.step = 'list';
          this.$buefy.snackbar.open(`Categoria editada com sucesso!`);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getCategorias() {
      this.isLoading = true;

      facilidadeCategoriaService
        .get()
        .then(({ data }) => {
          this.categorias = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    editCategoria(categoria) {
      this.form = categoria;
      this.step = 'edit';
    },
    deleteCategoria(categoria) {
      this.trashCategoria = categoria;
      this.isModalConfirmDeleteActive = true;
    },
    cancelDeleteCategoria() {
      this.trashCategoria = null;
      this.isModalConfirmDeleteActive = false;
    },
    confirmDeleteCategoria() {
      facilidadeCategoriaService
        .delete(this.trashCategoria.id)
        .then(() => {
          this.$buefy.snackbar.open(`Categoria excluída com sucesso!`);
          this.getCategorias();
          this.trashCategoria = null;
          this.isModalConfirmDeleteActive = false;
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            duration: 5000,
            message: 'Erro ao excluir categoria',
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        });
    },
  },
};
</script>
