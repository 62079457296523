import httpClient from './httpClient';
import httpAuthClient from './httpAuthClient';

const url = `facilidadeCategoria`;

export default {
  getById(id) {
    return httpClient.get(`${url}/${id}`);
  },
  get() {
    return httpClient.get(url);
  },
  getCategoriaFacilidades() {
    return httpClient.get(`${url}/GetCategoriaFacilidades`);
  },
  put(facilidadeCategoria) {
    return httpAuthClient.put(`/${url}`, facilidadeCategoria);
  },
  post(facilidadeCategoria) {
    return httpAuthClient.post(`/${url}`, facilidadeCategoria);
  },
  delete(id) {
    return httpAuthClient.delete(`/${url}/${id}`);
  },
};
