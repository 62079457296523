import httpClient from './httpClient';
import httpAuthClient from './httpAuthClient';

const url = `facilidade`;

export default {
  getById(id) {
    return httpClient.get(`${url}/${id}`);
  },
  getByCategoria(categoriaId) {
    return httpClient.get(`${url}/getByCategoria/${categoriaId}`);
  },
  getByTarifaTipoId(tarifaTipoId) {
    return httpClient.get(`${url}/getByTarifaTipo/${tarifaTipoId}`);
  },
  get() {
    return httpClient.get(url);
  },
  put(facilidade) {
    return httpAuthClient.put(`/${url}`, facilidade);
  },
  post(facilidade) {
    return httpAuthClient.post(`/${url}`, facilidade);
  },
  delete(id) {
    return httpAuthClient.delete(`/${url}/${id}`);
  },
};
